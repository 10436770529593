import './App.scss';
import { AppContextWrapper } from './shared/contexts/AppContextWrapper';
import { NotificationContextWrapper } from './shared/contexts/NotificationContextWrapper';
import { BrowserRouter } from 'react-router-dom';
import { UserContextWrapper } from './shared/contexts/UserContextWrapper';
import RootRouter from './routes/routes';
import { Provider } from 'react-redux';
import { store } from './store';
import { useEffect } from 'react';

function App() {
	useEffect(()=>{
		console.log('App mounted',process.env.REACT_APP_PASSWORD);
	},[])
	return (
		<NotificationContextWrapper>
			<Provider store={store}>
			<AppContextWrapper>
				<UserContextWrapper>
					<BrowserRouter>
						<RootRouter />
					</BrowserRouter>
				</UserContextWrapper>
			</AppContextWrapper>
			</Provider>
		</NotificationContextWrapper>
	);
}

export default App;
