
import { Layout } from '../home/Layout';
import AboutUs from './About';
import Team from './Team';
import ForgotPassword from '../forgotPassword';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
export const StyledContainer = styled.div<{ f?: any }>`
@media (min-width: 1200px) {
	/* Styles for devices with a minimum width of 1200px */
	min-height: ${(props) => (!!props.f ? "calc(100vh - 100px)" : "100vh")};
};
`;
export const StyledAdvisorImage = styled.img`
	width: 160px;
	height: 160px;
	border-radius: 50%;
	object-fit: cover;
`;


const AboutUsHome = () => {
    return (
        <Layout>
            <div>
                <AboutUs />
								<Container fluid className="bg-light">
				<StyledContainer className="" >
					<div>

						<Row>
							<Col>
								<p className="cmn-font-h2 text-center">advisors</p>
							</Col>
						</Row>
						<br />
						<br />


						<Row className="gy-5">
							<Col lg={3} md={4} sm={2} className="text-center ">
								<StyledAdvisorImage
									className="img-fluid rounded-circle advisor-image"
									src="Asserts/advisors/arul.png"
									alt=""
								/>

								<div className="mt-3 fs-5 fw-bold text-primary">Arul Murugan A</div>
								<br  className="d-none d-lg-block" />
								<br className="d-none d-lg-block"  />
								<br className="d-none d-lg-block" />
							</Col>
							<Col lg={3} md={4} sm={2} className="text-center ">
								<StyledAdvisorImage
									className="img-fluid rounded-circle advisor-image "
									src="Asserts/advisors/Dhanachandran E.jpeg"
									alt=""
								/>
								<div className="mt-3 fs-5 fw-bold text-primary">Dhanachandran E</div>
							
							</Col>
							<Col lg={3} md={4} sm={2} className="text-center ">
								<StyledAdvisorImage
									className="img-fluid rounded-circle advisor-image"
									src="Asserts/advisors/Karthik Netha.jpg"
									alt=""
								/>
								<div className="mt-3 fs-5 fw-bold text-primary">Karthik Netha</div>
							</Col>
							<Col lg={3} md={4} sm={2} className="text-center ">
								<StyledAdvisorImage
									className="img-fluid rounded-circle advisor-image "
									src="Asserts/advisors/Mohan E.jpg"
									alt=""
								/>
								<div className="mt-3 fs-5 fw-bold text-primary">Mohan E</div>
							</Col>
							<Col lg={3} md={4} sm={2} className="text-center ">
								<StyledAdvisorImage
									className="img-fluid rounded-circle advisor-image"
									src="Asserts/advisors/Santhanakrishnan P.jpeg"
									alt=""
								/>
								<div className="mt-3 fs-5 fw-bold text-primary">Santhanakrishnan P</div>
							</Col>
							<Col lg={3} md={4} sm={2} className="text-center ">
								<StyledAdvisorImage
									className="img-fluid rounded-circle advisor-image"
									src="Asserts/advisors/Sathish Nagarajen.jpeg"
									alt=""
								/>
								<div className="mt-3 fs-5 fw-bold text-primary">Sathish Nagarajen</div>
							</Col>
							<Col lg={3} md={4} sm={2} className="text-center">
								<StyledAdvisorImage
									className="img-fluid rounded-circle advisor-image"
									src="Asserts/advisors/Shiv Verma.JPG"
									alt=""
								/>
								<div className="mt-3 fs-5 fw-bold text-primary">Shiv Verma</div>
							</Col>
						</Row>
					</div>
				</StyledContainer>
			</Container>
			{/*  */}
			<Container fluid className="">
				<StyledContainer  >
					<div>
						<Row>
							<Col>
								<p className="cmn-font-h2 text-center">language ambassadors</p>
							</Col>
						</Row>
						<br />
						<br />


						<Row className="gy-5">
							<Col lg={3} md={4} sm={2} className="text-center ">
								<StyledAdvisorImage
									className="img-fluid rounded-circle advisor-image"
									src="Asserts/language ambassadors/Ahil Ibrahim_Malayalam.jpg"
									alt=""
								/>
								<div className="mt-3 fs-5 fw-bold text-primary">Ahil Ibrahim <br /> <span style={{color:"#b3b3b3"}}>Malayalam</span></div>
								<br  className="d-none d-lg-block" />
								<br className="d-none d-lg-block"  />
								<br className="d-none d-lg-block" />

							</Col>
							<Col lg={3} md={4} sm={2} className="text-center ">
								<StyledAdvisorImage
									className="img-fluid rounded-circle advisor-image "
									src="Asserts/language ambassadors/Boshudeb Chandra_Kannada.jpg"
									alt=""
								/>
									<div className="mt-3 fs-5 fw-bold text-primary">Boshudeb Chandra <br /> <span style={{color:"#b3b3b3"}}>Kannada & Telugu</span></div>
								<br />
								<br />
							</Col>
							<Col lg={3} md={4} sm={2} className="text-center ">
								<StyledAdvisorImage
									className="img-fluid rounded-circle advisor-image"
									src="Asserts/language ambassadors/Dr Abanob_Arabic.jpg"
									alt=""
								/>
									<div className="mt-3 fs-5 fw-bold text-primary">Dr Abanob<br /> <span style={{color:"#b3b3b3"}}>Arabic</span></div>

							</Col>
							<Col lg={3} md={4} sm={2} className="text-center ">
								<StyledAdvisorImage
									className="img-fluid rounded-circle advisor-image "
									src="Asserts/language ambassadors/Mubashir Kazi_Bengali.jpg"
									alt=""
								/>
								<div className="mt-3 fs-5 fw-bold text-primary">Mubashir Kazi <br /> <span style={{color:"#b3b3b3"}}>Bengali</span></div>

							</Col>
							<Col lg={3} md={4} sm={2} className="text-center ">
								<StyledAdvisorImage
									className="img-fluid rounded-circle advisor-image"
									src="Asserts/language ambassadors/Naveen Kumar R_Tamil & Technology.jpeg"
									alt=""
								/>
								<div className="mt-3 fs-5 fw-bold text-primary">Naveen Kumar R <br /> <span style={{color:"#b3b3b3"}}>Tamil & Technology</span></div>

							</Col>
							<Col lg={3} md={4} sm={2} className="text-center ">
								<StyledAdvisorImage
									className="img-fluid rounded-circle advisor-image"
									src={encodeURIComponent("Asserts/language ambassadors/Piyush Zawar_Marathi & Hindi.jpg")}
									alt=""
								/>
								<div className="mt-3 fs-5 fw-bold text-primary">Piyush Zawar <br /> <span style={{color:"#b3b3b3"}}>Marathi & Hindi</span></div>

								<br  className="d-none d-lg-block" />
								<br className="d-none d-lg-block"  />
								<br className="d-none d-lg-block" />

							</Col>
							<Col lg={3} md={4} sm={2} className="text-center">
								<StyledAdvisorImage
									className="img-fluid rounded-circle advisor-image"
									src={encodeURIComponent("Asserts/language ambassadors/Prathima Prakash_Telugu & Business Outreach.jpg")}
									alt=""
								/>
								<div className="mt-3 fs-5 fw-bold text-primary">Prathima Prakash <br /> <span style={{color:"#b3b3b3"}}>Telugu & Business Outreach</span></div>

							</Col>
							<Col lg={3} md={4} sm={2} className="text-center">
								<StyledAdvisorImage
									className="img-fluid rounded-circle advisor-image"
									src={encodeURIComponent("Asserts/language ambassadors/Raga Gourineni_Telugu & Business outreach.jpg")}
									alt=""
								/>
								<div className="mt-3 fs-5 fw-bold text-primary">Raga Gourineni<br /> <span style={{color:"#b3b3b3"}}> Telugu & Business Outreach</span></div>

							</Col>
							<Col lg={3} md={4} sm={2} className="text-center">
								<StyledAdvisorImage
									className="img-fluid rounded-circle advisor-image"
									src={encodeURIComponent("Asserts/language ambassadors/Sana Waqar_Urdu.jpg")}
									alt=""
								/>
								<div className="mt-3 fs-5 fw-bold text-primary">Sana Waqar <br /> <span style={{color:"#b3b3b3"}}> Urdu</span></div>

							</Col>
							<Col lg={3} md={4} sm={2} className="text-center">
								<StyledAdvisorImage
									className="img-fluid rounded-circle advisor-image"
									src={encodeURIComponent("Asserts/language ambassadors/Yuvaraj P_Tamil & Technology.jpeg")}
									alt=""
								/>
								<div className="mt-3 fs-5 fw-bold text-primary">Yuvaraj P <br /> <span style={{color:"#b3b3b3"}}> Tamil & Technology</span></div>

							</Col>
						</Row>
					</div>
				</StyledContainer>
				<br /><br />
			</Container>
                {/* <Team /> */}
            </div>
        </Layout>
    );
};

export default AboutUsHome;