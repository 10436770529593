import { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { useUserHook } from '../shared/hooks/useUserHook';
import Home from '../pages/home';
import FlashcardsRouter from './flashCardsRoutes';
import { VocabularyHome } from '../pages/vocabulary';
import AboutUs from '../pages/about_us';
import AuthRoutes from './authRoutes';
import { EventsHome } from '../pages/events';
import Profile from '../pages/Myprofile/profile';
import ChangePassword from '../pages/ChangePassword/password';
import MyCategoryRouter from './myCategoriesRoutes';
import ForgotPassword from '../pages/forgotPassword';
import { SharedCategories } from '../pages/flashCards/sharedCategory.tsx';


const RootRouter = () => {
  const { getMe } = useUserHook();
  useEffect(() => {
    getMe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const routes = useRoutes([
    {
      path: '/',
      element: <Home />
    },
    {
      path: '/auth/*',
      element: <AuthRoutes />
    },
    {
      path: '/flashCards/*',
      element: <FlashcardsRouter />
    },
    {
      path: '/vocabulary',
      element: <VocabularyHome />
    },
    {
      path: '/about',
      element: <AboutUs />
    },
    {
      path: '/events',
      element: <EventsHome />
    },
    {
      path: '/myCategories/*',
      element: <MyCategoryRouter />
    },
    {
      path: '/myProfile',
      element: <Profile />
    },
    {
      path: '/changePassword',
      element: <ChangePassword />
    },
    {
      path: '/forgotPassword',
      element: <ForgotPassword />
    },
    {
      path: '/sharedCategory',
      element: <SharedCategories />
    }
  ]);
  return routes;
};

export default RootRouter;